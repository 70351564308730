import moment from "./moment";

function increaseServiceAmount(selector, max, speed, type) {
  var count = 0;
  var increaseServiceAmountInterval = setInterval(function () {
    count += 1;
    if (type === "time") {
      var formatted = moment.utc(count * 1000).format("m'ss");
      $(selector).text(formatted);
    } else {
      $(selector).text(count);
    }
    if (count === max) {
      clearInterval(increaseServiceAmountInterval);
    }
  }, speed);
}

// hash detection
$(function () {
  function detectHash() {
    var currentHash = location.hash.slice(1);
    $("[data-hash='" + currentHash + "']")
      .addClass("active")
      .siblings()
      .removeClass("active");

    if ($(".body-wrapper .section-faq").length !== 0) {
      if (currentHash === "promo") {
        $(".menu-item").removeClass("active");
        $(".menu-item .promo").parents(".menu-item").addClass("active");
      } else {
        $(".menu-item").removeClass("active");
        $(".menu-item .faq").parents(".menu-item").addClass("active");
      }
    }
  }

  detectHash();
  $(".tab[data-hash]").click(function (event) {
    event.preventDefault();
    location.hash = $(this).attr("data-hash");
  });

  $('[href*="html#"]').click(function () {
    setTimeout(detectHash, 10);
    $(window).scrollTop(0);
  });
});

$(function () {
  if (window.promo) {
    renderPromo(window.promo);
  } else {
    Object.defineProperty(window, "promo", {
      set: function (data) {
        renderPromo(data);
      },
    });
  }

  function registerPromo(promoId) {
    api.registerPromo(promoId);
  }

  function renderPromo(promoData) {
    if (Array.isArray(promoData)) {
      const promoWrapper = $(`<div class="promo-wrapper"></div>`);
      let promohash = location.hash !== "" ? location.hash.split("#")[1] : "";

      promoData.forEach(function (value, index) {
        var promoItem = $(`<div class="item" data-type="${value.type}"></div>`);
        var promoId = value.promoId;
        var requestToJoin = value.requestToJoin;
        var firstDepositPromoJoinMsg = value.firstDepositPromoJoinMsg;

        promoItem.append(
          $(`
            ${
              value.imagePath
                ? `<img class="item-head image" src="${value.imagePath}" data-header="${promoId}" alt="${value.title}" />`
                : `<h3 class="item-head" data-header="${promoId}">
                      <div class="text flex">
                        <h1>${value.title}</h1>
                        <div class="left-pattern"></div>
                        <div class="right-pattern"></div>
                      </div>
                    </h3>`
            }
            <div class="item-content" ${promohash.length > 0 && promohash === promoId ? `style="display:block"` : ""}>
                ${value.imagePath && `<h3>${value.title}</h3>`}
                ${value.content}
                ${
                  requestToJoin
                    ? `<div
                      class="promo--modal--footer"
                      style="display: flex; align-items: center; justify-content: center; height: 50px;"
                    >
                      ${
                        firstDepositPromoJoinMsg
                          ? `
                            <div class="promo--modal--footer--info" style="color: #999;">
                              ${firstDepositPromoJoinMsg}
                            </div>
                          `
                          : `
                            <button
                              class="promo--modal--footer--register-promo"
                              data-id="${promoId}"
                              style="cursor:pointer;padding: 10px 20px;outline: none;width: 100px; display: flex; align-items: center; justify-content: center; background-color: #ff5100; color: #fff;border-radius: 4px; border: 1px solid #ff3300;"
                            >
                              申请参与
                            </button>
                            `
                      }
                  </div>`
                    : ""
                }
            </div>`)
        );

        promoWrapper.append(promoItem);
      });

      $(function () {
        $(".promo--modal--footer--register-promo").on("click", function (e) {
          e.preventDefault();
          var promoId = $(this).data("id");
          registerPromo(promoId);
        });
      });

      $(".section-promo .page").html(promoWrapper);
      loadPromoAnimation();

      if (promohash.length > 0) {
        setTimeout(function () {
          const scrollTop = $(`.item-head[data-header='${promohash}']`).offset().top - 50;
          $("html, body").animate({ scrollTop: scrollTop }, 1000);
        }, 500);
      }
    }
  }
});

$(function () {
  $(document).on("click", ".item > .item-head", function () {
    $(this).next().stop().slideToggle();
  });
});

function gameWrapperDetector() {
  var menuOffset = $(".main-menu .menu-links.games").offset().left;
  var windowWidth = $(window).width();
  var menuWidth = $(".main-menu .menu-links.games").outerWidth();

  var offsetRight = windowWidth - (menuOffset + menuWidth);
  var lotteryWrapperWidth = $(".lottery-dropdown-wrapper").innerWidth();

  if (offsetRight < lotteryWrapperWidth / 2) {
    $(".lottery-dropdown-wrapper").addClass("right");
  }
}

function loadPromoAnimation() {
  if ($(".section-content.section-promo").length !== 0) {
    $(".section-content.section-promo .page .item").each(function (k, i) {
      $(i).removeClass("ready");
      setTimeout(function () {
        $(i).addClass("ready");
      }, (k + 1) * 150);
    });
  }
}

// update header time
$(function () {
  setInterval(function () {
    $(".top-content .datetime").text(new Date().toLocaleString() + " ");
  }, 1000);
});

// Banner
function initBannerCarousel() {
  const bannerList = window.banner;
  const indexContainer = $("#index-banner.swiper-wrapper");
  const promoContainer = $("#promo-banner.swiper-wrapper");
  const isLoggedIn = $(".before-login.hide").length !== 0;
  bannerList &&
    bannerList !== 0 &&
    bannerList.forEach(function (item, index) {
      const { imageUrl, redirectTitle, redirectUrl, redirectKey, redirectGroup, redirectPage, bannerType } = item;
      if (imageUrl) {
        /**
         * Redirect Group Option
         * 0 - Promo
         * 1 - Join Us
         * 2 - Lottery
         * 3 - Product
         * 4 - Others inner page (Loyalty, Customer Service)
         * 5 - 自定义
         */
        const banner = $(`
        <div class="swiper-slide" data-swiper-autoplay="5000">
          <img ${redirectUrl ? `data-redirect=${redirectUrl}` : ""} class="img" src="${imageUrl}"/>
          <div class="img-bg" style="background-image: url(${imageUrl})"></div>
        </div>
      `);

        banner.click(() =>
          window.initBannerCarouselRedirection({
            redirectUrl,
            redirectGroup,
            redirectPage,
            isLoggedIn,
          })
        );

        /**
         * index append 首页type banner, promo append 优惠活动type banner
         * 轮播图类型 bannerType
         * 0 - 首页
         * 1 - 优惠活动
         * 2 - 其他
         */
        if (bannerType === 0) {
          indexContainer.append(banner);
        }

        if (bannerType === 1) {
          promoContainer.append(banner);
        }
      }
    });

  if (bannerList && bannerList.length) {
    var mySwiper = new Swiper(".swiper-container.banner", {
      speed: 400,
      autoplay: {
        delay: 5000,
      },
      loop: true,
      // If we need pagination
      pagination: {
        el: ".swiper-pagination.banner",
      },
    });
  }

  // If need marquee type trans, uncomment this
  // window.transInit();
}

//Load trans
$(function () {
  var announcementContainer = $("#announcementContainer");
  announcementContainer.children().remove();
  if (!!announcementContainer.length) {
    api.getNotice(function (response) {
      response.data.result.forEach(function (info, i) {
        announcementContainer.append($(`<div class="info${i == 0 ? " active" : ""}">${info.text}</div>`));
      });
    });
  }

  var transactionsContainer = $("#transactionsContainer");
  transactionsContainer.children().remove();
  if (!!transactionsContainer.length) {
    api.getTrans(function (response) {
      response.data.result.forEach(function (info, i) {
        // console.log(info);
        transactionsContainer.append(
          $(
            `<div class="info${i == 0 ? " active" : ""}">
              恭喜会员 ${info.username} 提款 ${-info.amount} 元成功，请及时查看银行卡账目。
            </div>`
          )
        );
      });
    });
  }

  // Sliding animation
  if ($(".info-wrapper").length) {
    var changeInterval = 5000;
    $(".info-wrapper").each(function (i, elem) {
      var intervalId = null;
      intervalId = window.setInterval(nextInfo, changeInterval);
      $(elem).on("mouseenter", function () {
        window.clearInterval(intervalId);
      });
      $(elem).on("mouseleave", function () {
        intervalId = window.setInterval(nextInfo, changeInterval);
      });

      function nextInfo() {
        var currentActive = $(elem).find(".info.active");
        currentActive.removeClass("active");
        if (currentActive.next().length) {
          currentActive.next().addClass("active");
        } else {
          $(elem).find(".info").eq(0).addClass("active");
        }
      }
    });
  }

  // Notice Click
  $(function () {
    $("#announcementContainer").click(function () {
      api.getNotice(function (res) {
        var list = res.data.result;
        var wrapper;
        if ($("#noticeWrapper").length) {
          wrapper = $("#noticeWrapper");
          wrapper.html("");
          wrapper.show();
        } else {
          wrapper = $(`<div id='noticeWrapper'></div>`);
        }

        var content = $(`<div class='notice-content'></div>`);
        var closeBtn = $(`<div class="close"></div>`);
        closeBtn.click(function () {
          $(this).parents("#noticeWrapper").fadeOut();
        });
        wrapper.append(content);
        content.append(closeBtn);
        content.append(`<h3>公告</h3>`);
        var tableWrapper = $(`<div class="table-wrapper"></div>`);
        content.append(tableWrapper);
        var table = $(`<table class="notice-table"></table>`);
        tableWrapper.append(table);

        table.append(`
        <tr>
          <th>时间</th>
          <th>公告详情</th>
        </tr>
      `);
        list.forEach(function (item, i) {
          var formattedTime = new Date(item.created).toLocaleString("zh-en", { hour12: false }).replace(/\//g, "-");
          var item = $(`
          <tr>
            <td class="notice-date">${formattedTime}</td>
            <td>${item.text}</td>
          <tr>
        `);
          table.append(item);
        });

        $("body").append(wrapper);
      });
    });
  });
});

if (Pace) {
  if (Pace.bar.progress < 100) {
    Pace.on("done", initBannerCarousel);
  } else {
    initBannerCarousel();
  }
} else {
  $(function () {
    $(window).load(initBannerCarousel);
    $(".btn-close").one("click", initBannerCarousel);
  });
}

$(document).ready(function () {
  $(".menu-links.lottery").hover(function () {
    $(this).find(".lottery-dropdown-wrapper").toggle();
  });

  // Detect visible
  var increaseServiceAmountExecuted = false;
  $(window).scroll(detectServiceVisible);

  function detectServiceVisible() {
    if ($(".servicelist-wrapper").visible() && !increaseServiceAmountExecuted) {
      increaseServiceAmountExecuted = true;
      increaseServiceAmount(".dp-no", 23, 100, "");
      increaseServiceAmount(".wd-no", 122, 20, "time");
      $(".dp-barred").addClass("active");
      $(".wd-barred").addClass("active");
    }

    if (increaseServiceAmountExecuted) {
      $(window).unbind("scroll", detectServiceVisible);
    }
  }

  $(".section-faq .switchable .tab").click(function (e) {
    console.log("clicked");
    e.preventDefault();
    var index = $(this).index();
    var dataType = $(this).data("hash");

    if (dataType === "promo") {
      $(".menu-item").removeClass("active");
      $(".menu-item .promo").parents(".menu-item").addClass("active");
    } else {
      $(".menu-item").removeClass("active");
      $(".menu-item .faq").parents(".menu-item").addClass("active");
    }

    $(this).siblings().removeClass("active");
    $(this).addClass("active");

    $(this).parents(".nav").find(".page").eq(index).addClass("active").siblings().removeClass("active");
  });
  gameWrapperDetector();
});
