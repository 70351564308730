// depends on jQuery
// trans
var transOn = true;
window.transInit = function() {
    var transLists = $(".trans");
    var speed = $(".trans").data("speed") || 1;

    transLists.each(function(i, elem) {
        var text = elem.innerText;
        var textElem = $(`<div>${text}</div>`);
        textElem
            .css("min-width", "100%")
            .css("display", "inline-block")
            .css("margin-right", "40px")
            .css("box-sizing", "border-box");
        $(elem)
            .text("")
            .css("overflow", "hidden");
        var textWrapper = $("<div></div>")
            .css("white-space", "nowrap")
            .css("will-change", "transform");
        textWrapper.append(textElem);
        textWrapper.append(textElem.clone());
        $(elem).append(textWrapper);

        var pos = 0;
        function transRun() {
            if (transOn) {
                pos -= 1 * speed;
                if (Math.abs(pos) >= textElem.width() + 40) pos = 0;
                textWrapper.css("transform", `translateX(${pos}px)`);
            }
            requestId = window.requestAnimationFrame(transRun);
        }
        var requestId = window.requestAnimationFrame(transRun);
        var timeoutId = undefined;

        // pause looping when window resizing
        $(window).on("resize", function() {
            window.cancelAnimationFrame(requestId);
            clearTimeout(timeoutId);
            timeoutId = setTimeout(function() {
                requestId = window.requestAnimationFrame(transRun);
            }, 200);
        });

        // pause looping when hover trans
        $(".trans").hover(
            function() {
                if (requestId) {
                    window.cancelAnimationFrame(requestId);
                    requestId = undefined;
                }
            },
            function() {
                if (!requestId) {
                    requestId = window.requestAnimationFrame(transRun);
                }
            }
        );

        // stop looping text when trans is out of viewport
        $(window).on("scroll", function() {
            var boundaries = elem.getBoundingClientRect();
            if (boundaries.top < 0 || boundaries.bottom > window.innerHeight || boundaries.height <= 1) {
                transOn = false;
            } else {
                transOn = true;
            }
        });
    });
};
